import {
  TeamOutlined,
  FileImageOutlined,
  CheckOutlined,
  ArrowUpOutlined
} from "@ant-design/icons";
import { Card, Col, Row, Statistic } from 'antd';
import React from 'react'
import FamilyMemberGrid from "../components/FamilyMemberGrid";


const Dashboard = () => {
 
  return (
    <>
        <div className="page__main" >
          {/* <Row gutter={16}>
            <Col span={8}>
              <Card bordered={false}>
              <Statistic
                  title="Total Family Members"
                  value={11.28}
                  precision={2}
                  valueStyle={{ color: '#3f8600' }}
                  prefix={<ArrowUpOutlined />}
                  //suffix="%"
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered={false}>
              <Statistic
                  title="Total Family Gallery"
                  value={11.28}
                  precision={2}
                  valueStyle={{ color: '#3f8600' }}
                  prefix={<ArrowUpOutlined />}
                  //suffix="%"
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card bordered={false}>
              <Statistic
                  title="Family Type"
                  value={11.28}
                  precision={2}
                  valueStyle={{ color: '#3f8600' }}
                  prefix={<ArrowUpOutlined />}
                  //suffix="%"
                />
              </Card>
            </Col>
          </Row> */}
          <FamilyMemberGrid />
        </div>
    </>
  )
}

export default Dashboard
import {Navigate, useRoutes } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import AddFamily from "./pages/Add-Family";
import EditFamily from "./pages/EditFamily";
import Gallery from "./pages/Gallery";
import ViewFamily from "./pages/View-Family";
import DashboardLayout from "./layouts/DashboardLayout";
import PublicRoutesLayout from "./layouts/PublicRoutesLayout";
import ProtectedRoute from "./layouts/ProtectedRoutesLayout";
import Page404 from "./pages/Page404";
import Register from "./pages/Register";
import { useSelector } from 'react-redux';
import Home from "./pages/HomePage";


export default function Router () {
    const { user, isAuthenticated, loading } = useSelector(state => state.auth)
//     if(localStorage.getItem("token") === null) {
//         window.location.href ='/';
//    }
    return useRoutes([
       
        { path: '/family', element: <DashboardLayout/>,
        children: [
           {path: 'dashboard', element: <Dashboard/>},
           { path: 'add-Family', element: <AddFamily/>},
           { path: 'edit-Family/:id', element: <EditFamily/>},
           { path: 'view-Family', element: <ViewFamily /> },
           { path: 'gallery', element: <Gallery /> },
       ],
    },
    {
        path: '/',
        element: <PublicRoutesLayout />,
        children: [
        {  index: true, element: <Home /> },
        { path: 'register', element: <Register /> },
        // { path: 'home', element: <Home /> },    
        { path: 'page404', element: <Page404 /> },    
        ],
    },
    { path: "*", element: <Navigate to="/page404" replace={true} /> },
    ])

}
import React, {  useState, useEffect, Fragment } from 'react'
import { newMember, getMembers, clearError } from '../Actions/familyMemberAction'
import { allRelation, clearErrors } from '../Actions/relationAction'
import { NEW_MEMBER_REQUEST } from '../constants/familyMemberConstants'
import { useAlert } from 'react-alert'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Row, Form, Col, message, Radio, Select } from 'antd';
import { useNavigate } from 'react-router-dom'
import Loader from '../components/Loader'

const AddFamily = ({history}) => {
  const [form] = Form.useForm();
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [fname, setFName] = useState(0);
  const [mname, setMName] = useState('');
  const [lname, setLName] = useState('');
  const [dob, setDOB] = useState(0);
  const [dom, setDOM] = useState(0);
  const [gender, setGender] = useState('');
  const [marriedStatus, setMarriedStatus] = useState('');
  const [loginpermission, setLoginPermission] = useState('');
  const [relationShip, setRelationShip] = useState('');
  const [relationFrom, setRelationFrom] = useState('');
  const [imagesPreview, setImagesPreview] = useState([]);
  const [images, setImages] = useState('');
  const [parentid, setparentid] = useState('');
  //const [rules, setRules] = useState();

  
  const { error, success, loading, message } = useSelector(state => state.newmembers || {});
  const {  membersList } = useSelector(state => state.memberList || {});
  
  // if(membersList.list?.length > 0){
  //     setRules("{[ { required: true, message: 'Please select Relationship!'}]}")
  // }else{
  //     setRules('0')
  // }
  // console.log(rules)
  //{ (membersList.list)?rules={[ { required: true, message: 'Please select Relationship!'}]}:''}
  const base64Url = localStorage.getItem("token").split('.')[1];
  const base64 = JSON.parse(window.atob(base64Url.replace('-', '+').replace('_', '/')));
  
  useEffect(() => {
      dispatch(getMembers())
    if (error) {
        alert.error(error);
        dispatch(clearError())
    }
    if (success) {
        //window.location='/family/add-Family'
        window.location.reload();
        navigate('/family/add-Family');
        alert.success(message);
        form.resetFields();
        dispatch(getMembers())
        dispatch({ type: NEW_MEMBER_REQUEST })
    }

}, [dispatch, alert, error, success, history])
  
  const onFinish = async(values) => {
      const formData = new FormData();
      formData.set('adminID', base64.sub);
      formData.set('userNameOrEmail', values.email);
      formData.set('Fname', values.fname);
      formData.set('Mname', (values.mname)?values.mname:'NUll');
      formData.set('Lname', (values.lname)?values.lname:'NUll');
      formData.set('DOB', values.dob);
      formData.set('gender', values.gender);
      formData.set('DOM', (values.dom)?values.dom:'00-00-0000');
      formData.set('parent_id', (values.relationShip ==='Father' || values.relationShip ==='Wife')?'0':values.relationFrom );
      formData.set('relationTo', values.relationShip);
      formData.set('relationFromTo', (values.relationShip ==='Father' || values.relationShip ==='Child')?'0':values.relationFrom);
      formData.set('loginPermission', '0');
      formData.set('marriageStatus', values.marriedStatus);
      //formData.set('imageName', images??'pull');
      formData.append('profileImg', images);
      
      dispatch(newMember(formData))
  }
  const onChange = e => {
    //console.log(e.target.name)
    if (e.target.name === 'avatar') {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                //setAvatarPreview(reader.result)
                setImages(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0])

    } 
}
  const { relations } = useSelector(state => state.relations || '');
  useEffect(() => {
    dispatch(allRelation());

    if (error) {
        alert.error(error);
        dispatch(clearErrors())
    }


}, [dispatch, alert, error, history])
  
  return (
    <div className="page__main" >
      {loading? Loader : false}
    <Form form={form} layout='vertical' onFinish={onFinish} encType='multipart/form-data'>
        <Row>
          <Col lg={24} xs={24} className="flex d-flex gap-3">
              <Form.Item label="Email" name="email" 
              tooltip="Mention Any Person Mail!" 
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                  whitespace: true
                },
              ]}>
              <input type="text" className='form-control' 
                value={email}
                onChange={(e) => setEmail(e.target.value)}/>
              </Form.Item>
            <Form.Item label="First Name" name="fname" tooltip="First Name!" 
              rules={[
                {
                  required: true,
                  message: 'Please input your First Name!',
                  whitespace: true
                },
              ]}>
              <input type="text" className='form-control' 
                value={fname}
                onChange={(e) => setFName(e.target.value)}/>
            </Form.Item>
            <Form.Item label="Middle Name (Optional)" name="mname">
              <input type="text" className='form-control' value={mname}
                onChange={(e) => setMName(e.target.value)}/>
            </Form.Item>
            <Form.Item label="Last Name (Optional)" name="lname">
              <input type="text" className='form-control' 
                value={lname}
                onChange={(e) => setLName(e.target.value)}/>
            </Form.Item>
          </Col>
          <Col lg={24} xs={24} className="flex d-flex gap-3 margin-top">
            <Form.Item label="Date Of Birth" name="dob" tooltip="Your DOB!" 
              rules={[
                {
                  required: true,
                  message: 'Please input your DOB!',
                  whitespace: true
                },
              ]}>
              <input type="date" className='form-control' 
                value={dob}
                onChange={(e) => setDOB(e.target.value)}/>
            </Form.Item>
            <Form.Item label="Date Of Marriage" name="dom" tooltip="Your DOMs!">
              <input type="date" className='form-control'value={dom}
                onChange={(e) => setDOM(e.target.value)}/>
            </Form.Item>
            {/* <Form.Item label="Login Permission" name="loginpermission" rules={[
                {
                  required: true,
                  message: 'Please select Login Permission!',
                },
              ]}>
              <Radio.Group 
                value={loginpermission}
                onChange={(e) => setLoginPermission(e.target.value)}>
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </Form.Item> */}
            <Form.Item label="Your Profile" name="profileimg" tooltip="Your Profile/Image!" 
              rules={[
                {
                  required: true,
                  message: 'Please input your profile!',
                  whitespace: true
                },
              ]}>
              <input type="file" name="avatar" className='form-control' onChange={onChange}/>
            </Form.Item>
            <Form.Item label="Select Relation" name="relationShip" tooltip="Your Select Relation!" 
              rules={[
                {
                  required: true,
                  message: 'Please input your Select Relation!',
                  whitespace: true
                },
              ]}>
              <select className='form-control' name='relationShip' 
                value={relationShip}
                onChange={(e) => setRelationShip(e.target.value)}>
                <option value="">--Select Relations--</option>
                {relations.list?.map((name) =>
                  <option value={name.relationName}>{name.relationName}</option>
                )}
              </select>
            </Form.Item>
          </Col>
          <Col lg={24} xs={24} className="flex d-flex gap-3 margin-top">
          <Form.Item label="Gender" name="gender" rules={[
                {
                  required: true,
                  message: 'Please select gender!',
                },
              ]}>
              <Radio.Group  
                value={gender}
                onChange={(e) => setGender(e.target.value)} >
                <Radio value="Male">Male</Radio>
                <Radio value="Female">Female</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Marriage Status" name="marriedStatus" rules={[
                {
                  required: true,
                  message: 'Please select satus!',
                },
              ]}>
              <Radio.Group 
                value={marriedStatus}
                onChange={(e) => setMarriedStatus(e.target.value)}>
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={24} xs={24} className="flex d-flex gap-3 margin-top">
          <Form.Item label="Relation From (Whose Relationship With Whom)" name="relationFrom">
              <Radio.Group 
                value={relationFrom}
                onChange={(e) => setRelationFrom(e.target.value)}>
                  {membersList.list?.map((name) =>
                  <Radio value={name.fmID}>{name.Fname}</Radio>
                )}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row>
        <div className='d-flex justify-content-end margin-top  gap-3'style={{ textAlign: 'right' }}>
          <button className='primary-btn' type='submit'>Save</button>
          <button className='secondary-btn' type='submit'onClick={() => {
              form.resetFields();
            }}>Clear</button>
        </div>
        </Row>
      </Form>
      </div>
  )
}


export default AddFamily
 
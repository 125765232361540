import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
// import '../resourses/style.css';
import Loader from '../layouts/Loader'
import MetaData from '../layouts/MetaData'
import { login, clearErrors } from '../Actions/loginAction';
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Form, Input } from 'antd';

const Year = new Date().getFullYear();

const Login = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const alert = useAlert();

    const { isAuthenticated, error, message, loading } = useSelector(state => state.auth);
    //const redirect = location.search ? location.search.split('=')[1] : '/'
    useEffect(() => {

        if (isAuthenticated) {
            window.location.href ='/family/dashboard';
            //history.push('/family/dashboard')
        }
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }
    }, [dispatch, alert, isAuthenticated, error])
    const onFinish = async(values) => {
            dispatch(login(values.email, values.password))
    }

return (
    <div id="login" className='margin-top-login h-screen d-flex justify-content-center align-items-center auth'>
        <div className='w-400 p-4'>
        {/* <div className='w-400 card p-4'> */}
            {/* <div className="text-center"> */}
                {/* <img className="mb-4" src="/logo.png" alt="" width="52" height="52" /> */}
                {/* <h1 className="h3 mb-3 fw-normal" style={{fontSize:'14px'}}>Welcome , Login Your Account</h1> */}
            {/* </div> */}
            {/* <hr /> */}
        <Form layout='vertical' onFinish={onFinish} className='login_form'>
        <h1>Welcome back!</h1>
        <p>Login to your account.</p>
            <Form.Item label="Enter The Email" name="email" rules={[ {
            type: 'email',
            message: 'The input is not valid E-mail!',
        },
        { required: true, message: 'Please input your username!' }]}
        >
            <input
                type="email"
                id="email_field"
                value={email || ''}
                onChange={(e) => setEmail(e.target.value)}
            />
        </Form.Item>
        <Form.Item label="Enter The Password" name='password' rules={[{ required: true, message: 'Please input your password!' }]}>
            <input
                type="password"
                id="password_field"
                value={password || ''}
                onChange={(e) => setPassword(e.target.value)}
            />
        </Form.Item>
    <div className='d-flex justify-content-between align-items-center margin-top'>
        {/* <Link to='/register'>Click Here To Register</Link> */}
        <button id="login_button" type="submit">Login</button>
    </div>
    </Form>

        </div>
    </div>
    );
};

export default Login;

import React from 'react';

import AppHero from './hom';
import AppAbout from './about';
import AppFeature from './feature';
import AppPricing from './pricing';
import AppContact from './contact';
import Login from '../Login';
import { Col, Divider, Row } from 'antd';
import Register from '../Register';

function AppHome() {
  return (
    <div className="main">
      <AppHero/>
      <AppAbout/>
      <AppFeature/>
      <AppPricing/>
      <AppContact/>
    </div>
  );
}

export default AppHome;
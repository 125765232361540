// import 'antd/dist/antd.min.css'
import './resourses/global.css'
import React, { useEffect } from "react";
import { logoutAllTabs } from "./auth/auth";
import Router from "./routes";

function App() {
  useEffect(() => {
    logoutAllTabs()
  }, [])
  return (
    <>
     <Router/>
    </>
  );
}
export default App;

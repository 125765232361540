import React from 'react'
import { Button, Col, Form, Input, Row } from 'antd';
import { Carousel } from 'antd';
import Login from '../Login';


const items = [
  {
    key: '1',
    title: 'A family tree is a visual representation.',
    content: 'family tree. noun. a chart showing the genealogical relationships and lines of descent of a familyAlso called: genealogical tree.',
  },
  {
    key: '2',
    title: 'Family trees provide you with a distinctive.',
    content: "It can help you to better understand your family's history and heritage, connect with relatives you may not have known about, and potentially provide access to alternative citizenships and the resources they offer.",
  },
  {
    key: '3',
    title: 'After emphasizing the universal character of the family.',
    content: 'Discovering more about your ancestors, celebrating family traditions, embracing your culture, and understanding where you came from can open your eyes to how beautiful and unique you are. It can also give your sense of self-worth and belonging a boost.',
  },
]
const AppHero = () => {
  return (
    <div id="hero" className="heroBlock">
    <Carousel>
      {items.map(item => {
        return (
          <div key={item.key} className="container-fluid">
            <Row>
              <Col span={12}>
              <div className="content">
                <h3>{item.title}</h3>
                <p>{item.content}</p>
                <div className="btnHolder">
                  <Button type="primary" size="large">Learn More</Button>
                  {/* <Button size="large"><i className="fas fa-desktop"></i> Watch a Demo</Button> */}
                </div>
              </div>  
              </Col>
              <Col span={12}>
                  <Login />
              </Col>
            </Row>
            
          </div>  
        );
      })}
      
    </Carousel>
  </div>
  )
}

export default AppHero

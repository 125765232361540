import React from 'react';
import '../resourses/home.css';

//import 'antd/dist/antd.css';
import 'antd/dist/reset.css';
import AppHeader from './common/header';
import AppFooter from './common/footer';
import AppHome from './common/Home';

import { Layout } from 'antd';
const { Header, Content, Footer } = Layout;

function HomePage() {
        return (
          <Layout className="mainLayout">
            <Header>
              <AppHeader/>
            </Header>
            <Content>
              <AppHome/>
            </Content>
            <Footer>
              <AppFooter/>  
            </Footer>      
          </Layout>
        );
      }
export default HomePage;
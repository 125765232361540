import React, { useEffect } from 'react'
import '../resourses/view-family.css'
import { useDispatch, useSelector } from 'react-redux'
import { getTreeMembers } from '../Actions/familyMemberAction';


const ViewFamily = () => {
  const disptach = useDispatch();

  const { loading, error, membersTreeList, success } = useSelector(state => state.treeList)

  const id = localStorage.getItem("mid")
  useEffect(() =>{
      disptach(getTreeMembers(id))
  },[disptach])

  return (
  <div className="page__main">
    {/* { console.log(membersTreeList) } */}
    { (membersTreeList?.length !=0 && membersTreeList !=undefined)?
      <div className="tree">
        {(membersTreeList?.length !=0)?
        <ul>
          <li>
            <a href="#"><img src={ membersTreeList?.image } className='img' /> { membersTreeList?.name } {(membersTreeList?.wifeName)? '|':''} {(membersTreeList?.wifeName)?<img src={ membersTreeList?.wimage } className='img' />:''} { membersTreeList?.wifeName }</a>
            { (membersTreeList?.children?.length !=0)?
            <ul>
            { membersTreeList?.children?.map((child) => {
              return <li>
                <a href="#"><img src={ child?.image?child?.image:"/img/user.png"} className='img' /> {child.name} {(child?.wifeName)? '|':''} {(child?.wifeName)?<img src={ child?.wimage } className='img' />:''} { child?.wifeName }</a>
                {(child?.children.length !=0)?
                <ul>
                { child?.children?.map((gchild) => {
                 return <li>
                      <a href="#"><img src={ gchild?.image?gchild?.image:"/img/user.png"} className='img' /> {gchild.name} {(gchild?.wifeName)? '|':''} {(gchild?.wifeName)?<img src={ gchild?.wimage } className='img' />:''} { gchild?.wifeName }</a>
                      {(gchild?.children.length !=0)?
                      <ul>
                      { gchild?.children?.map((ggchild) => {
                        return <li>
                           <a href="#"><img src={ ggchild?.image?ggchild?.image:"/img/user.png"} className='img' /> {ggchild.name} {(ggchild?.wifeName)? '|':''} {(ggchild?.wifeName)?<img src={ ggchild?.wimage } className='img' />:''} { ggchild?.wifeName }</a>
                           {(ggchild?.children.length !=0)?
                           <ul>
                           { ggchild?.children?.map((gggchild) => {
                            return <li>
                              <a href="#"><img src={ gggchild?.image?gggchild?.image:"/img/user.png"} className='img' /> {gggchild.name} {(gggchild?.wifeName)? '|':''} {(gggchild?.wifeName)?<img src={ gggchild?.wimage } className='img' />:''} { gggchild?.wifeName }</a>
                            </li>
                             })}
                           </ul>:''}
                        </li>
                         })}
                      </ul>:''}
                    </li>
                  })}
                </ul>:''}
              </li>
              })}
            </ul>:''}
          </li>
        </ul>:''}
    </div>:'If you have added father for the first time. So please login once after logout!'}
  </div>
  )
}

export default ViewFamily
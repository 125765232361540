import { axiosInatance } from '../helpers/axiosInstance';

import { 
    ALL_GALLERY_REQUEST,
    ALL_GALLERY_SUCCESS,
    ALL_GALLERY_FAIL,

    NEW_GALLERY_REQUEST,
    NEW_GALLERY_SUCCESS,
    NEW_GALLERY_FAIL,


    CLEAR_ERROR,
} from '../constants/galleryConstant'

export const getgallery = () => async (disptach) =>{
    try{
        disptach({ type:ALL_GALLERY_REQUEST })

        const { data } = await axiosInatance.get('/v1/gallery')
        disptach({
            type: ALL_GALLERY_SUCCESS,
            payload: data
        })
    }catch (error) {
        disptach({
            type:ALL_GALLERY_FAIL,
            //success: error.response.data.success,
            payload: error.response.data.message
        })
    }
}

export const newGallery = (productData) => async (dispatch) => {
    try {

        dispatch({ type: NEW_GALLERY_REQUEST })

        const { data } = await axiosInatance.post(`/v1/gallery`, productData)

        dispatch({
            type: NEW_GALLERY_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_GALLERY_FAIL,
            payload: error.response.data.message
        })
    }
}
export const clearError = () => async (disptach) =>{
            disptach({
                type:CLEAR_ERROR
            })
}   

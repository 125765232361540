export const ALL_GALLERY_REQUEST = 'ALL_GALLERY_REQUEST'
export const ALL_GALLERY_SUCCESS = 'ALL_GALLERY_SUCCESS'
export const ALL_GALLERY_FAIL = 'ALL_GALLERY_FAIL'


export const NEW_GALLERY_REQUEST = 'NEW_GALLERY_REQUEST'
export const NEW_GALLERY_SUCCESS = 'NEW_GALLERY_SUCCESS'
export const NEW_GALLERY_RESET = 'NEW_GALLERY_RESET'
export const NEW_GALLERY_FAIL = 'NEW_GALLERY_FAIL'

export const CLEAR_ERROR = 'CLEAR_ERROR'
import { 
    ALL_MEMBER_REQUEST,
    ALL_MEMBER_SUCCESS,
    ALL_MEMBER_FAIL,

    ALL_MEMBERTREE_REQUEST,
    ALL_MEMBERTREE_SUCCESS,
    ALL_MEMBERTREE_FAIL,

    ALL_MEMBEREDITABLE_REQUEST,
    ALL_MEMBEREDITABLE_SUCCESS,
    ALL_MEMBEREDITABLE_FAIL,

    CLEAR_ERROR,

    NEW_MEMBER_REQUEST,
    NEW_MEMBER_SUCCESS,
    NEW_MEMBER_RESET,
    NEW_MEMBER_FAIL,
} from '../constants/familyMemberConstants'

export const familyMemberListReducers = (state = { membersList:[] }, action) => {
    // console.log(action.payload.success)
    switch(action.type){
        case ALL_MEMBER_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case ALL_MEMBER_SUCCESS:
            return {
                loading: false,
                membersList: action.payload,
                membersCount: action.payload.membersCount
            }
        case ALL_MEMBER_FAIL:
            return {
                loading: false,
                //success: action.success,
                error: action.payload
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }

}
export const familyTreeMemberReducers = (state = { membersTreeList:[] }, action) => {
    switch(action.type){
        case ALL_MEMBERTREE_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case ALL_MEMBERTREE_SUCCESS:
            return {
                loading: false,
                membersTreeList: action.payload
            }
        case ALL_MEMBERTREE_FAIL:
            return {
                loading: false,
                //success: action.success,
                error: action.payload
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }

}
export const familyMemberEditablReducers = (state = { membersEditList:[] }, action) => {
    switch(action.type){
        case ALL_MEMBEREDITABLE_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case ALL_MEMBEREDITABLE_SUCCESS:
            return {
                loading: false,
                membersEditList: action.payload
            }
        case ALL_MEMBEREDITABLE_FAIL:
            return {
                loading: false,
                //success: action.success,
                error: action.payload
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }

}
export const newMemberReducer = (state = { data: {} }, action) => {
    switch (action.type) {

        case NEW_MEMBER_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_MEMBER_SUCCESS:
            return {
                loading: false,
                data: action.payload,
                success: action.payload.success,
                message: action.payload.message
            }

        case NEW_MEMBER_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_MEMBER_RESET:
            return {
                ...state,
                success: false
            }

        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

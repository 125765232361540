import { axiosInatance } from '../helpers/axiosInstance';
import { 

    ALL_RELATION_REQUEST,
    ALL_RELATION_SUCCESS,
    ALL_RELATION_FAIL,
    CLEAR_ERRORS

} from '../constants/relationConstants'


// Get all users
export const allRelation = () => async (dispatch) => {
    try {

        dispatch({ type: ALL_RELATION_REQUEST })

        const { data } = await axiosInatance.get('/v1/relations')

        dispatch({
            type: ALL_RELATION_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_RELATION_FAIL,
            payload: error.response.data.message
        })
    }
}
// Clear Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}
import { axiosInatance } from '../helpers/axiosInstance';

import { 
    ALL_MEMBER_REQUEST,
    ALL_MEMBER_SUCCESS,
    ALL_MEMBER_FAIL,

    ALL_MEMBERTREE_REQUEST,
    ALL_MEMBERTREE_SUCCESS,
    ALL_MEMBERTREE_FAIL,

    ALL_MEMBEREDITABLE_REQUEST,
    ALL_MEMBEREDITABLE_SUCCESS,
    ALL_MEMBEREDITABLE_FAIL,

    CLEAR_ERROR,

    NEW_MEMBER_REQUEST,
    NEW_MEMBER_SUCCESS,
    NEW_MEMBER_FAIL,
} from '../constants/familyMemberConstants'

export const getMembers = () => async (disptach) =>{
    try{
        disptach({ type:ALL_MEMBER_REQUEST })

        const { data } = await axiosInatance.get('/v1/member')
        disptach({
            type: ALL_MEMBER_SUCCESS,
            payload: data
        })
    }catch (error) {
        disptach({
            type:ALL_MEMBER_FAIL,
            //success: error.response.data.success,
            payload: error.response.data.message
        })
    }
}
export const getTreeMembers = (id) => async (disptach) =>{
    try{
        disptach({ type:ALL_MEMBERTREE_REQUEST })

        const { data } = await axiosInatance.get('/v1/member/treeNew/'+id)
        //console.log(data)
        disptach({
            type: ALL_MEMBERTREE_SUCCESS,
            payload: data
        })
    }catch (error) {
        disptach({
            type:ALL_MEMBERTREE_FAIL,
            //success: error.response.data.success,
            payload: error.response.data.message
        })
    }
}
export const getEditableMembers = () => async (disptach) =>{
    try{
        disptach({ type:ALL_MEMBEREDITABLE_REQUEST })

        const { data } = await axiosInatance.get('/v1/member/EditableList')
        //console.log(data)
        disptach({
            type: ALL_MEMBEREDITABLE_SUCCESS,
            payload: data
        })
    }catch (error) {
        disptach({
            type:ALL_MEMBEREDITABLE_FAIL,
            //success: error.response.data.success,
            payload: error.response.data.message
        })
    }
}
export const newMember = (familyData) => async (dispatch) => {
    try {

        dispatch({ type: NEW_MEMBER_REQUEST })

        const { data } = await axiosInatance.post(`/v1/member`, familyData)
        dispatch({
            type: NEW_MEMBER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_MEMBER_FAIL,
            payload: error.response.data.message
        })
    }
}
export const clearError = () => async (disptach) =>{
            disptach({
                type:CLEAR_ERROR
            })
}   

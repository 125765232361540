import { Form } from 'antd';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { register, clearErrors } from '../Actions/loginAction';
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux';
import { REGISTER_USER_REQUEST } from '../constants/loginConstants'

const Register = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');
    const alert = useAlert();
    const navigate = useNavigate();

    const { isAuthenticated, success, error, message, loading } = useSelector(state => state.auth);
    useEffect(() => {
        // if (isAuthenticated) {
        //     window.location='/';
        // }
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }
        if (success) {
            alert.success('created successfully');
            navigate('/');
            dispatch({ type: REGISTER_USER_REQUEST })
        }

    }, [dispatch, alert, isAuthenticated, error])
    const onFinish = async(values) => {
        dispatch(register(values.name,values.email,values.mobile,values.password,values.confirmPassword))
    }
  return (
    <div className='h-screen d-flex justify-content-center align-items-center auth'>
        <div className='w-400 card p-4'>
            <div className="text-center">
                <img className="mb-4" src="/logo.png" alt="" width="52" height="52" />
                <h1 className="h3 mb-3 fw-normal" style={{fontSize:'14px'}}>Welcome , Register Your Account</h1>
            </div>
            <hr />
            <Form layout='vertical' onFinish={onFinish} encType='multipart/form-data'>
                <Form.Item label="Name" name='name' rules={[{ required: true, message: 'Please input your name!' }]}>
                    <input type='text'  value={name || ''}
                    onChange={(e) => setName(e.target.value)}/>
                </Form.Item>
                <Form.Item label="Email" name="email" rules={[{ required: true, type:'email', message: 'Please input your email!' }]}>
                    <input type='text' value={email || ''}
                    onChange={(e) => setEmail(e.target.value)}/>
                </Form.Item>
                <Form.Item label="Mobile" name='mobile' rules={[{ required: true, message: 'Please input your mobile!' }]}>
                    <input type='text' value={mobile || ''}
                onChange={(e) => setMobile(e.target.value)}/>
                </Form.Item>
                <Form.Item label="Password" name='password' rules={[{ required: true, message: 'Please input your password!' }]}>
                    <input type='password' value={password || ''}
                onChange={(e) => setPassword(e.target.value)}/>
                </Form.Item>
                <Form.Item label="Confirm Password" name='confirmPassword' rules={[{ required: true, message: 'Please input your confirm password!' }]}>
                    <input type='password' value={confirmPassword || ''}
                onChange={(e) => setconfirmPassword(e.target.value)}/>
                </Form.Item>
            <div className='d-flex justify-content-between align-items-center margin-top'>
                <Link to='/'>Click Here To Login</Link>
                <button className='primary-btn' type="submit">Register</button>
            </div>
            </Form>
        </div>
    </div>
);
};
export default Register
import React from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import Nav from '../components/Nav'
import jwt_decode from "jwt-decode";


const DashboardLayout = ({children}) => {
  const navigate = useNavigate();
  const { user, isAuthenticated, loading } = useSelector(state => state.auth)
 if(!isAuthenticated && localStorage.getItem("token") === null) {
      window.location.href ='/';
 }
 let decodedToken = jwt_decode(localStorage.getItem("token"));
    console.log("Decoded Token", decodedToken);
    let currentDate = new Date();

    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
     // console.log("Token expired.");
      localStorage.removeItem("token")
      localStorage.removeItem("mid")
      window.location='/';
    } else {
      //console.log("Valid token");   
      //result = true;
    }
  return (
    <> 
      <Nav children={children}>
        <Outlet/>
      </Nav>
    </>
  )
}

export default DashboardLayout
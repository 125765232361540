import { 
    ALL_GALLERY_REQUEST,
    ALL_GALLERY_SUCCESS,
    ALL_GALLERY_FAIL,

    NEW_GALLERY_REQUEST,
    NEW_GALLERY_SUCCESS,
    NEW_GALLERY_RESET,
    NEW_GALLERY_FAIL,
    CLEAR_ERROR,
} from '../constants/galleryConstant'

export const getGalleryReducers = (state = { galleryList:[] }, action) => {
    // console.log(action.payload.success)
    switch(action.type){
        case ALL_GALLERY_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case ALL_GALLERY_SUCCESS:
            return {
                loading: false,
                galleryList: action.payload
            }
        case ALL_GALLERY_FAIL:
            return {
                loading: false,
                //success: action.success,
                error: action.payload
            }
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }

}

export const newGalleryReducer = (state = { gallery: {} }, action) => {
    switch (action.type) {

        case NEW_GALLERY_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_GALLERY_SUCCESS:
            return {
                loading: false,
                gallery: action.payload,
                success: action.payload.success,
                message: action.payload.message
            }

        case NEW_GALLERY_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_GALLERY_RESET:
            return {
                ...state,
                success: false
            }

        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

import { 

    ALL_RELATION_REQUEST,
    ALL_RELATION_SUCCESS,
    ALL_RELATION_FAIL,
    CLEAR_ERRORS

} from '../constants/relationConstants'

export const allRelationReducer = (state = { relations: [] }, action) => {
    switch (action.type) {

        case ALL_RELATION_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case ALL_RELATION_SUCCESS:
            return {
                ...state,
                loading: false,
                relations: action.payload
            }

        case ALL_RELATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}
export const ALL_MEMBER_REQUEST = 'ALL_MEMBER_REQUEST'
export const ALL_MEMBER_SUCCESS = 'ALL_MEMBER_SUCCESS'
export const ALL_MEMBER_FAIL = 'ALL_MEMBER_FAIL'

export const ALL_MEMBERTREE_REQUEST = 'ALL_MEMBERTREE_REQUEST'
export const ALL_MEMBERTREE_SUCCESS = 'ALL_MEMBERTREE_SUCCESS'
export const ALL_MEMBERTREE_FAIL = 'ALL_MEMBERTREE_FAIL'

export const ALL_MEMBEREDITABLE_REQUEST = 'ALL_MEMBEREDITABLE_REQUEST'
export const ALL_MEMBEREDITABLE_SUCCESS = 'ALL_MEMBEREDITABLE_SUCCESS'
export const ALL_MEMBEREDITABLE_FAIL = 'ALL_MEMBEREDITABLE_FAIL'

export const NEW_MEMBER_REQUEST = 'NEW_MEMBER_REQUEST'
export const NEW_MEMBER_SUCCESS = 'NEW_MEMBER_SUCCESS'
export const NEW_MEMBER_RESET = 'NEW_MEMBER_RESET'
export const NEW_MEMBER_FAIL = 'NEW_MEMBER_FAIL'

export const CLEAR_ERROR = 'CLEAR_ERROR'
import { Button, Form, Upload } from 'antd'
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { newGallery, getgallery, clearError } from '../Actions/galleryAction'
import { allRelation, clearErrors } from '../Actions/relationAction'
import { NEW_GALLERY_REQUEST } from '../constants/galleryConstant'
import React, { useState } from 'react'
import { useAlert } from 'react-alert'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Gallery = ({history}) => {
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  //const [imagesPreview, setImagesPreview] = useState([])
  const formItemLayout = { labelCol: { span: 6, }, wrapperCol: { span: 14, }, };
  const normFile = (e) => {
   // console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    console.log(e)
    return e?.fileList;
  };
  const {  membersList, error, success, loading, message } = useSelector(state => state.members || {});
  console.log(success)
  const base64Url = localStorage.getItem("token").split('.')[1];
  const base64 = JSON.parse(window.atob(base64Url.replace('-', '+').replace('_', '/')));
  useEffect(() => {
    dispatch(getgallery())
    if (error) {
        alert.error(error);
        dispatch(clearError())
    }
    if (success) {
        navigate('/family/gallery');
        alert.success(message);
        dispatch({ type: NEW_GALLERY_REQUEST })
    }

}, [dispatch, alert, error, success, history])
  const onFinish = (values) => {
    const formData = new FormData();
    console.log('Received values of form: ', values);

    images.forEach(image => {
      formData.append('images', image)
  })

  dispatch(newGallery(formData))
  };
  const onChange = e => {

    const files = Array.from(e.target.files)
   // setImagesPreview([]);
    setImages([])
    files.forEach(file => {
        const reader = new FileReader();

        reader.onload = () => {
            if (reader.readyState === 2) {
               // setImagesPreview(oldArray => [...oldArray, reader.result])
                setImages(oldArray => [...oldArray, reader.result])
            }
        }

        reader.readAsDataURL(file)
    })
}
  return (
    <>
    <div className="page__main">
      <h4>Upload Family Photo!</h4>
        <Form
        name="validate_other"
        {...formItemLayout}
        onFinish={onFinish}
        initialValues={{
          'input-number': 3,
          'checkbox-group': ['A', 'B'],
          rate: 3.5,
        }}
        style={{
          maxWidth: 600,
        }}
        encType='multipart/form-data'
      >
          <Form.Item
              name="upload"
              label="Upload"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              extra="Image Uploading"
            >
              <Upload name="logo" action="/upload.do" listType="picture">
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
          </Form.Item>
        </Form>
    </div>
</>
    
  )
}

export default Gallery
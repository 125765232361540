import React from 'react'


const EditFamily = () => {
  
  
  return (
    <div className="page__main" >
     <h4>Edit Form Coming Soon...</h4>
    </div>
  )
}


export default EditFamily
 
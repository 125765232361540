import React, { useEffect } from 'react'
import { EditOutlined,FormOutlined } from '@ant-design/icons';
import { Card, List } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux'
import { getEditableMembers } from '../Actions/familyMemberAction';
import Loader from '../components/Loader'

const FamilyMemberGrid = () => {
  const disptach = useDispatch();

  const { loading, error, membersEditList, success } = useSelector(state => state.memberEditList)
console.log(loading)
  useEffect(() =>{
      disptach(getEditableMembers())
  },[disptach])

  return (
        <div className="page__main" >
        {loading? Loader : false}
        <List
            grid={{ gutter: 16, column: 4, }}
            dataSource={membersEditList?.list}
            renderItem={(item) => (
            <List.Item>
                <Card title={item.Fname.concat(' ',(item.Lname ==='NUll')?'':item.Lname)}>
                  <img src={item.profileImg} style={{height: '200px', width:'200px'}} />
                  DOB: {moment(item.DOB).format("DD-MM-YYYY")}
                  <a href={"edit-Family/"+item.fmID} style={{marginLeft: '80px'}}><FormOutlined /> </a>
                </Card>
            </List.Item>
            )}
        />
        </div>
  )
}

export default FamilyMemberGrid

import React from 'react';

import { List, Card, Button } from 'antd';

const data = [
  {
    title: 'Basic',
    bgcolor:"#eea726",
    content: [
      {
        price: '₹500/',
        // space: '1 GB of space',
        user: '5 Generation',
        support: '24/7 support',
        backup: 'Safe, reliable backup',
        access: 'Access from anywhere'
      }
    ]
  },
  {
    title: 'Premium',
    bgcolor:"#1677ff",
    content: [
      {
        price: '₹1000/',
        // space: '5 GB of space',
        user: '10 Generation',
        support: '24/7 support',
        backup: 'Safe, reliable backup',
        access: 'Access from anywhere'
      }
    ]
  },
  {
    title: 'Enterprise',
    bgcolor:"#78a87f",
    content: [
      {
        price: '₹2000/',
        // space: 'Unlimited space',
        user: 'Unlimited Generation',
        support: '24/7 support',
        backup: 'Safe, reliable backup',
        access: 'Access from anywhere'
      }
    ]
  }
];

function AppPricing() {
  return (
    <div id="pricing" className="block pricingBlock bgGray">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2>Choose a plan to fit your needs</h2>
        </div>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 1,
            md: 3,
            lg: 3,
            xl: 3,
            xxl: 3,
          }}
          dataSource={data}
          renderItem={item => (
            <List.Item>
              <Card title={item.title}>
                <p className="large">{item.content[0].price}<span style={{fontSize: "17px",color: "#fefffe",
              backgroundColor: item.bgcolor,
              borderRadius: "8px",
              padding: "1px 7px"}}>Yearly</span></p>
                <p>{item.content[0].space}</p>
                <p>{item.content[0].user}</p>
                <p>{item.content[0].support}</p>
                <p>{item.content[0].backup}</p>
                <p>{item.content[0].access}</p>
                <Button type="primary" size="large" style={{marginTop: "14px",backgroundColor: item.bgcolor }}><i className="fab fa-telegram-plane"
                style={{fontSize: "15px"}}> Get Started</i> </Button>
              </Card>
            </List.Item>
          )}
        />
      </div>
    </div>  
  );
}

export default AppPricing;
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'

import { familyMemberListReducers,newMemberReducer, familyTreeMemberReducers, familyMemberEditablReducers } from './reducers/familyMemberReducers'
import { allRelationReducer } from './reducers/relationReducer'
import { newGalleryReducer, getGalleryReducers } from './reducers/galleryReducers';
import { authReducer } from './reducers/loginReducers'

const reducer = combineReducers({
    newmembers: newMemberReducer,
    memberList: familyMemberListReducers,
    memberEditList: familyMemberEditablReducers,
    gallery: newGalleryReducer,
    galleryList : getGalleryReducers,
    treeList:familyTreeMemberReducers,
    auth: authReducer,
    relations: allRelationReducer,

})

let initialState = {}

const middleware = [thunk];

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store;
